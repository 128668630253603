const fields = [
  {
    label: 'Name',
    key: 'name',
    alternateMatches: ['number', 'name'],
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    example: 'SE/04/2024/00573659',
  },
  {
    label: 'Service Time',
    key: 'service_time',
    alternateMatches: ['Service Date & Time', 'service_time'],
    fieldType: {
      type: 'input',
    },
    example: '08/04/2024 15:30:13',
  },
  {
    label: 'Create Date',
    key: 'create_date',
    alternateMatches: ['Created Date & Time', 'create_date'],
    fieldType: {
      type: 'input',
    },
    example: '08/04/2024 15:30:13',
  },
  {
    label: 'Customer ID',
    key: 'customer_id',
    alternateMatches: ['Customer Name', 'customer_id'],
    fieldType: {
      type: 'input',
    },
    example: '196',
  },
  {
    label: 'Sequence ID',
    key: 'sequence_id',
    alternateMatches: ['Category', 'sequence_id'],
    fieldType: {
      type: 'input',
    },
    example: '10',
  },
  {
    label: 'Membership Num',
    key: 'membership_num',
    alternateMatches: ['Membership Number', 'membership_num'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Member ID',
    key: 'member_id',
    alternateMatches: ['Member Name', 'member_id'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Member Contact No',
    key: 'member_contact_no',
    alternateMatches: ['Mobile', 'mobile', 'member_contact_no'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Policy No',
    key: 'policy_no',
    alternateMatches: ['Policy Number', 'policy_no'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Member Type',
    key: 'member_type',
    alternateMatches: ['Member Type', 'member_type'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Type',
    key: 'type',
    alternateMatches: ['Service Type', 'type'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Vehicle Type',
    key: 'vehicle_type',
    alternateMatches: ['Vehicle Type', 'vehicle_type'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Vehicle Plate',
    key: 'vehicle_plate',
    alternateMatches: ['vehicle_plate', 'Vehicle Plate'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Product ID',
    key: 'product_id',
    alternateMatches: ['product_id', 'Service'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Provider ID',
    key: 'provider_id',
    alternateMatches: ['provider_id', 'Provider'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Driver Name',
    key: 'driver_name',
    alternateMatches: ['driver_name', 'driver', 'Driver', 'Driver Name'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Driver Num',
    key: 'driver_num',
    alternateMatches: ['driver_num', 'Driver Mobile Number'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'From Location',
    key: 'from_location',
    alternateMatches: ['from_location', 'From - Location', 'From Location'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'To Location',
    key: 'to_location',
    alternateMatches: ['to_location', 'To - Location', 'To Location'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'State',
    key: 'state',
    alternateMatches: ['state', 'Status'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Trip Sheet Number',
    key: 'credit_proforma_number',
    alternateMatches: ['credit_proforma_number', 'Trip Sheet Number'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Amount Collected',
    key: 'route_rate',
    alternateMatches: ['route_rate', 'Amount Collected'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Rating',
    key: 'vendor_rating',
    alternateMatches: ['vendor_rating', 'Rating'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Date Time From',
    key: 'date_time_from',
    alternateMatches: ['date_time_from', 'From date', 'From - Date'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Date Time To',
    key: 'date_time_to',
    alternateMatches: ['date_time_to', 'To date', 'To - Date'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Comments',
    key: 'comments',
    alternateMatches: ['comments', 'Comments'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Customer C/O',
    key: 'credit_customer_co',
    alternateMatches: ['credit_customer_co', 'Customer C/O'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Chassis',
    key: 'policy_chassis',
    alternateMatches: ['policy_chassis', 'Chassis'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
] as const;

export default fields;
